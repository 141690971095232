<template>
  <div>
    <div class="page_container">
      <BackComponent :title="$t('VerifyYourAccount')" />
    </div>

    <div class="page_container success_svg">
      <img
        class="successful_illustration_image"
        src="/img/successful-illustration.png"
        alt="passport"
      />
    </div>
    <div class="card_one page_container">
      <div class="header_desc">
        <h5>{{ this.$t("verificationCompeted") }}</h5>
        <p>
          {{ this.$t("verificationCompetedDesc") }}
        </p>
      </div>
    </div>

    <div class="page_container">
      <div style="margin-top: 24px">
        <ChopbetButton variant="variant" @click="goToHome"
          >{{ this.$t("goHome") }}
        </ChopbetButton>
      </div>
    </div>
  </div>
</template>

<script>
import BackComponent from "../../../../components/ui/BackComponent.vue";
import ChopbetButton from "../../../../components/ui/ChopbetButton.vue";

export default {
  name: "Setting",
  components: {
    BackComponent,
    ChopbetButton,
  },
  data: function () {
    return {};
  },
  methods: {
    goToHome() {
      this.$router.push("/my-account");
    },
  },
};
</script>

<style src="./index.css" scoped></style>
